import React from 'react';
import { globalHistory } from '@reach/router';
import queryString from 'query-string';

import Layout from '../../components/Layout';
import SEO from '../../components/Seo';
import styles from './index.module.scss';

const IndexPage = () => {
  const location = globalHistory.location;
  const search = location.search ? queryString.parse(location.search) : {};
  const { activationLink } = search;

  return (
    <Layout>
      <SEO
        title="QR-Code not activated | QRpid"
        description="This QR-Code has to be activated through the QRpid console before first usage."
        keywords={[`qrcodes`, `assets`, `devices`, `manage`]}
      />
      <div className={styles.index}>
        <div className="hero">
          <div className="grid-container">
            <div className="grid-x grid-margin-x">
              <div className="cell small-12">
                <h1>This QR-Code is currently not activated.</h1>
                <p>Please log into your account and activate it.</p>
                {activationLink && (
                  <a className="button" href={activationLink}>
                    Activate QR-Code now
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
